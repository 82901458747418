import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'message'
import { SaveBtn } from 'components/display'

import EditItemInfo from './EditItemInfo'

const def = () => {}

const EditInfo = (props) => {
  const [loading, setLoading] = useState(false)

  const { company_id, doc = {}, onChange = def, onClose = def, lang } = props

  const onItem = (it) => {
    doc.fee_type = it.fee_type
    doc.fee = it.fee

    onChange(doc)
  }

  const onStatus = (val, name) => {
    doc[name] = val

    onChange(doc)
  }

  const onCh = (val, name) => {
    doc[name] = val

    onChange(doc)
  }

  const onSave = async () => {
    try {
      setLoading(true)
      await props.company.updateCompanyFeature(company_id, doc)
      onClose()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const { fee_type, fee } = doc
  const item = { status: '', fee_type, fee }
  return (
    <PageView>
      <EditItemInfo label="HLO Application" item={item} onChange={onItem} />
      <EditItemInfo
        label="Leave Request"
        name="leave_req"
        name_status="leave_req_status"
        status={doc.leave_req_status}
        item={doc.leave_req}
        onChange={onCh}
        onStatus={onStatus}
      />
      <EditItemInfo
        label="Document Request"
        name="doc"
        name_status="doc_status"
        status={doc.doc_status}
        item={doc.doc}
        onChange={onCh}
        onStatus={onStatus}
      />
      <EditItemInfo
        label="Reimbursement"
        name="reimburse"
        name_status="reimburse_status"
        status={doc.reimburse_status}
        item={doc.reimburse}
        onChange={onCh}
        onStatus={onStatus}
      />
      <EditItemInfo
        label="Attendance"
        name="attendance"
        name_status="attendance_status"
        status={doc.attendance_status}
        item={doc.attendance}
        onChange={onCh}
        onStatus={onStatus}
      />

      <EditItemInfo
        label="Timesheet"
        name="timesheet"
        name_status="timesheet_status"
        status={doc.timesheet_status}
        item={doc.timesheet}
        onChange={onCh}
        onStatus={onStatus}
      />

      <EditItemInfo
        label="Payroll"
        name="payroll"
        name_status="payroll_status"
        status={doc.payroll_status}
        item={doc.payroll}
        onChange={onCh}
        onStatus={onStatus}
      />
      <EditItemInfo
        label="Overtime"
        name="overtime"
        name_status="overtime_status"
        status={doc.overtime_status}
        item={doc.overtime}
        onChange={onCh}
        onStatus={onStatus}
      />
      <EditItemInfo
        label="Salary Advance"
        name="salary_advance"
        name_status="salary_advance_status"
        status={doc.salary_advance_status}
        item={doc.salary_advance}
        onChange={onCh}
        onStatus={onStatus}
      />

      <SaveBtn
        loading={loading}
        text={lang.getMessage('Save', 'บันทึก')}
        close={lang.getMessage('Cancel', 'ยกเลิก')}
        onClick={onSave}
        onClose={onClose}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('lang', 'company')(observer(EditInfo))
