import styled from 'styled-components'

const Avatar = (props) => {
  const { img_url } = props
  const content = img_url ? (
    <Image src={img_url} />
  ) : (
    <Icon className="far fa-user-circle" />
  )
  return <PageView>{content}</PageView>
}

const PageView = styled.div`
  width: 100%;
`

const Image = styled.img`
  width: 45px;
  height: 45px;
`

const Icon = styled.i`
  font-size: 24px;
`

export default Avatar
