import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Migration extends BaseStore {
  constructor() {
    super()
    this.observable({
      counter: 0,
    })
    state = this
  }

  async process() {
    const url = `${config.api}/v1/admin/account/migrations/employee`

    const res = await http.put(url, {})
    const counter = res.body.counter || 0

    runInAction(() => {
      state.counter = counter
    })
  }
}

const store = new Migration()
export default store
