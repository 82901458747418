import styled from 'styled-components'
import { Button } from 'antd'

import { Gmail } from 'icon'

const Btn = (props) => {
  const onClick = () => {
    let { name, onClick } = props
    if (onClick) onClick(name)
  }

  let { disabled, text, loading } = props

  let click
  if (!loading && !disabled) {
    click = onClick
  }

  const txt = loading ? (
    text
  ) : (
    <Text>
      <Gmail /> {text}
    </Text>
  )
  return (
    <PageView>
      <Button disabled={disabled} loading={loading} onClick={click}>
        {txt}
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  button {
    color: ${(p) => p.theme.color.black};
    background-color: white;
    width: 100%;
    height: 48px;
    border-radius: 39px;
    border: 1px solid #b4d5d7;
    font-weight: 700;
  }

  button:hover {
    color: ${(p) => p.theme.color.black};
  }
`

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
  }
`

export default Btn
