import { Row, Col } from 'components/table'
import { format } from 'utils'

import { col } from './col'

const RowItem = (props) => {
  const { item } = props

  const link = `/company/${item.company_id}`
  return (
    <Row link={link}>
      <Col {...col.name}>{item.name}</Col>
      <Col {...col.basic}>{format.toMobile(item.mobile)}</Col>
      <Col {...col.email}>{item.email}</Col>
      <Col {...col.date}>{format.toNameDate(item.created_at)}</Col>
    </Row>
  )
}

export default RowItem
