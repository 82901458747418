import member from './Member'
import lang from './Lang'
import province from './Province'
import consent from './Consent'
import account from './Account'
import message from './Message'
import company from './Company'
import userAdmin from './UserAdmin'
import userRole from './UserRole'
import employee from './Employee'
import migration from './Migration'
import menu from './Menu'

/* eslint-disable */
export default {
  member,
  lang,
  account,
  province,
  consent,
  company,
  menu,
  message,
  employee,
  userAdmin,
  userRole,
  migration,
}
/* eslint-enable */
