import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Radio } from 'components/input'
import { Line, Label } from 'components/display'

import { manage_menu } from './menu'

const Info = (props) => {
  const { data = {}, lang, onChange } = props

  const doc = data.reimburse || {}
  const onCh = (val, name) => {
    doc[name] = val
    data.reimburse = doc
    onChange(data)
  }
  return (
    <PageView>
      <Line>
        <Label text={lang.getMessage('Reimbursement', 'การเบิกเงินคืน]')} />
        <Radio
          menu={lang.getMessage(manage_menu.eng, manage_menu.th)}
          name="module"
          value={doc.module || 'none'}
          onChange={onCh}
        />
      </Line>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('lang')(observer(Info))
