import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Row, Col, Tab } from 'components/display'

const CompanyMenu = (props) => {
  const { id, selected, children, content } = props
  const navigate = useNavigate()

  const onChange = (val) => {
    let link
    switch (val) {
      case 'member':
        link = `/company/${id}/member`
        break
      case 'role':
        link = `/company/${id}/role`
        break
      case 'feature':
        link = `/company/${id}/feature`
        break
      default:
        link = `/company/${id}`
    }
    navigate(link)
  }

  return (
    <PageView>
      <Menu>
        <Row>
          <Col lg={12} md={114} xs={24}>
            <Tab menu={menu_list} selected={selected} onChange={onChange} />
          </Col>
          <Col lg={12} md={10} xs={24}>
            {content}
          </Col>
        </Row>
      </Menu>

      {children}
    </PageView>
  )
}

const menu_list = [
  { label: 'ข้อมูลตลาด', value: 'information' },
  { label: 'ผู้ดูแล', value: 'member' },
  { label: 'สิทธิ์การเข้าถึง', value: 'role' },
  { label: 'Features', value: 'feature' },
]

const PageView = styled.div`
  width: 100%;
  max-width: 1000px;
`

const Menu = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

export default CompanyMenu
