export const col = {
  name: { lg: 7, md: 6, xs: 6 },
  role: { lg: 14, md: 12, xs: 12 },
  btn: { lg: 3, md: 3, xs: 3 },
}

export const header_list = [
  { title: 'ชื่อ', size: col.name },
  { title: 'สิทธิ', size: col.role },
]
