import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { GmailBtn } from 'components/button'
import { message } from 'message'

const LoginPage = (props) => {
  const [loading, setLoading] = useState(false)

  const onGoogle = async () => {
    try {
      setLoading(true)
      await props.member.loginByGoogle()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  return (
    <PageView>
      <Title>Login to Backoffice</Title>

      <GmailBtn loading={loading} text="Login with Google" onClick={onGoogle} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 40px;

  ${(p) => p.theme.media.mobile} {
    padding: 30px 0px;
    background-color: transparent;
  }
`

const Title = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
`

export default inject('member')(observer(LoginPage))
