import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Row, Col } from 'components/table'
import { EditBtn, RemoveBtn } from 'components/circle'
import { message } from 'message'
import { ConfirmDialog } from 'dialog'

import { col } from './col'

const RowItem = (props) => {
  const [isConfirm, setIsConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const { company_id, item, onEdit } = props

  const onConfirm = () => setIsConfirm(true)
  const onClose = () => setIsConfirm(false)
  const clickEdit = () => onEdit(item)

  const onDelete = async () => {
    try {
      onClose()
      setLoading(true)
      const { user_role_id } = item
      await props.userRole.deleteUserRole({ company_id, user_role_id })
      message.success('ลบสิทธิ์การเข้าถึง')
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  const toText = (name, list = []) => {
    return (
      <RoleLine>
        {name}: {list.join(', ')}
      </RoleLine>
    )
  }

  const getRoleList = () => {
    const view_list = []
    const manage_list = []
    for (const it of nameList) {
      const { name, key, value } = it
      const data = item[key] || {}
      const v = data[value] || 'none'

      if (v === 'view') {
        view_list.push(name)
      } else if (v === 'manage') {
        manage_list.push(name)
      }
    }

    const viewName = 'View'
    const mangeName = 'Manage'
    const view = view_list.length > 0 ? toText(viewName, view_list) : undefined
    const manage =
      manage_list.length > 0 ? toText(mangeName, manage_list) : undefined
    return (
      <>
        {view}
        {manage}
      </>
    )
  }

  return (
    <>
      <Row align="middle">
        <Col {...col.name}>{item.name}</Col>
        <Col {...col.role}>{getRoleList()}</Col>
        <Col {...col.btn} align="right">
          <Button>
            <EditBtn onClick={clickEdit} />
            <RemoveBtn loading={loading} onClick={onConfirm} />
          </Button>
        </Col>
      </Row>
      <ConfirmDialog
        title="ลบสิทธิ์การเข้าถึง"
        message={`คุณต้องการลบสิทธิ์การเข้าถึงออก (${item.name}) ใช่มัย?`}
        visible={isConfirm}
        onClose={onClose}
        onConfirm={onDelete}
      />
    </>
  )
}

const nameList = [
  {
    name: 'Dashboard',
    key: 'dashboard',
    value: 'module',
  },
  { name: 'Compnay', key: 'company', value: 'module' },
  { name: 'Employee', key: 'employee', value: 'module' },
  {
    name: 'Private Information',
    key: 'employee',
    value: 'private_information',
  },

  { name: 'Leave Reqest', key: 'leave_req', value: 'module' },
  { name: 'Document', key: 'doc', value: 'module' },
  {
    name: 'Reimburse',
    key: 'reimburse',
    value: 'module',
  },
  { name: 'Attendance', key: 'doc', value: 'module' },
  { name: 'Payroll', key: 'payroll', value: 'module' },
  {
    name: 'Administrator',
    key: 'admin',
    value: 'module',
  },
  {
    name: 'Setting',
    key: 'setting',
    value: 'module',
  },
]

const RoleLine = styled.div`
  width: 100%;
  font-size: 14px;
`

const Button = styled.div`
  width: 100%;
  display: flex;
  column-gap: 8px;
`

export default inject('userRole')(observer(RowItem))
