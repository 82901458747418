import styled from 'styled-components'

const Filter = (props) => {
  const { children } = props

  return <PageView>{children}</PageView>
}

const PageView = styled.div`
  width: 100%;
  padding-bottom: 24px;
`

export default Filter
