import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'message'
import { validator } from 'utils'
import { Input, SelectList } from 'components/input'
import { Line } from 'components/display'
import { EditDrawer } from 'drawer'

import rules from './rules'

const AdminCreator = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [validate, setValidate] = useState({})

  const role_list = props.userRole.toJS().menu_list || []
  const { visible, company_id, doc = {}, lang, onClose } = props

  useEffect(() => {
    if (visible) {
      setData({ ...doc })
    }
  }, [visible])

  const onSave = async () => {
    try {
      const checker = validator.process(rules, data)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setLoading(true)
      data.company_id = company_id
      await props.userAdmin.updateAdminAccount(data)
      onClose()

      message.success(
        lang.getMessage('save admin successfully', 'บันทึกผู้ดูแลเรียบร้อยแล้ว')
      )
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  const onUser = (val, name) => {
    data[name] = val
    setData({ ...data })

    delete validate[name]
    setValidate({ ...validate })
  }

  const onRole = (val, name) => {
    data.user_role_id = val
    setData({ ...data })

    delete validate[name]
    setValidate({ ...validate })
  }

  return (
    <EditDrawer
      title={lang.getMessage('Admin Information', 'ข้อมูลผู้ดูแล')}
      text={lang.getMessage('Save', 'บันทึก')}
      visible={visible}
      loading={loading}
      onClose={onClose}
      onSave={onSave}
    >
      <PageView>
        <Line>
          <Input
            label={lang.getMessage('Name', 'ชื่อ')}
            name="name"
            value={data.name}
            onChange={onUser}
            invalid={lang.getValidate(validate.name, 'name', 'ชื่อ')}
          />
        </Line>
        <Line>
          <Input
            label={lang.getMessage('Surname', 'นามสกุล')}
            name="surname"
            value={data.surname}
            onChange={onUser}
            invalid={lang.getValidate(validate.surname, 'surname', 'นามสกุล')}
          />
        </Line>
        <Line>
          <Input
            label={lang.getMessage('Email', 'อีเมล')}
            name="email"
            value={data.email}
            onChange={onUser}
            disabled={data.admin_customer_id}
            invalid={lang.getValidate(validate.email, 'email', 'อีเมล')}
          />
        </Line>

        <SelectList
          label={lang.getMessage('Role', 'สิทธิ์การเข้าถึง')}
          name="user_role_id"
          value={data.user_role_id}
          menu={role_list}
          onChange={onRole}
          invalid={lang.getValidate(
            validate.user_role_id,
            'role',
            'สิทธิ์การเข้าถึง'
          )}
        />
      </PageView>
    </EditDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('userAdmin', 'userRole', 'lang')(observer(AdminCreator))
