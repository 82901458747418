import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { cloneDeep } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Content, Pagination } from 'components/display'
import Loading from 'components/loading'
import { PrimaryBtn } from 'components/button'
import { EditDrawer } from 'drawer'
import { message } from 'message'
import { validator } from 'utils'

import rules from './rules'
import Filter from './Filter'
import CompanyCreator from './CompanyCreator'
import DataList from './DataList'

const original = {
  seq: 1,
  name: '',
  status: 'active',
}

const CompanyList = (props) => {
  const [loading, setLoading] = useState(false)
  const [creating, setCreating] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [data, setData] = useState(cloneDeep(original))
  const [validate, setValidate] = useState({})

  const [filter, setFilter] = useState({ text: '', status: 'active' })

  const navigate = useNavigate()

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        props.company.getList(filter),
        props.company.getCount(filter),
      ])
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.company, filter])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const onFilter = (val) => setFilter({ ...val })
  const onChange = async ({ index, per_page }) => {
    try {
      setLoading(true)

      await props.company.getList({ ...filter, index, per_page })
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const onCreate = () => setIsCreate(true)
  const onCloseCreate = () => setIsCreate(false)

  const onData = (val) => setData({ ...val })
  const onValidate = (val) => setValidate({ ...val })

  const onCreateCompany = async () => {
    try {
      const checker = validator.process(rules, data)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setCreating(true)

      const doc = await props.company.createCompany(data)

      setData(cloneDeep(original))
      setCreating(false)

      navigate(`/company/${doc.company_id}`)
    } catch (e) {
      message.error(e.message)
      setCreating(false)
    }
  }

  const display = props.company.toJS().display

  const { page, list } = display

  const btn = <PrimaryBtn text="เพิ่มบริษัท" onClick={onCreate} />

  return (
    <Content title="รายการบริษัท" right={btn}>
      <Filter filter={filter} onChange={(val) => setFilter({ ...val })} />

      <Loading loading={loading}>
        <DataList list={list} />
        <Pagination page={page} onChange={onChange} />
      </Loading>
      <EditDrawer
        title="ข้อมูลบริษัท"
        text="สร้าง"
        visible={isCreate}
        loading={creating}
        onClose={onCloseCreate}
        onSave={onCreateCompany}
      >
        <CompanyCreator
          data={data}
          validate={validate}
          onChange={onData}
          onValidate={onValidate}
        />
      </EditDrawer>
    </Content>
  )
}

export default inject('company')(observer(CompanyList))
