import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { Input, SelectList } from 'components/input'

import { col } from './col'

const def = () => {}

const EditItemInfo = (props) => {
  const {
    label,
    name,
    name_status,
    item = {},
    status,
    onChange = def,
    onStatus,
  } = props

  const onCh = (val, n) => {
    item[n] = val

    onChange(item, name)
  }

  const content = name_status ? (
    <SelectList
      name={name_status}
      label={label}
      value={status}
      menu={StatusMenu}
      onChange={onStatus}
    />
  ) : undefined

  return (
    <PageView>
      <Row>
        <Col {...col.basic} position="left-responsive">
          {content}
        </Col>

        <Col {...col.basic} position="center-responsive">
          <SelectList
            name="fee_type"
            label="Fee Type"
            value={item.fee_type}
            menu={FeeMenu}
            onChange={onCh}
          />
        </Col>

        <Col {...col.basic} position="right-responsive">
          <Input
            label="Fee"
            name="fee"
            value={item.fee}
            onChange={onCh}
            isNumber
          />
        </Col>
      </Row>
    </PageView>
  )
}

const StatusMenu = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
]

const FeeMenu = [
  { name: 'None', value: 'none' },
  { name: 'Per Employee', value: 'per_employee' },
  { name: 'Per Month', value: 'per_month' },
]

const PageView = styled.div`
  width: 100%;
`

export default EditItemInfo
