import Joi from 'joi'

import { validator } from 'utils'

const role = Joi.object({
  name: validator.getRuleString('ชื่อ'),
  email: validator.getRuleString('อีเมล'),
}).options({ allowUnknown: true })

export default role
