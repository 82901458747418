import styled from 'styled-components'
import { Row, Col } from 'antd'

const HeaderView = (props) => {
  const { list = [] } = props
  const content = list.map((it, i) => {
    return (
      <Col key={i} {...it.size}>
        {it.title}
      </Col>
    )
  })
  return (
    <PageView>
      <Row>{content}</Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 0px 20px;
`

export default HeaderView
