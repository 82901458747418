if (typeof window === 'undefined')
  global.window = { location: { host: 'testhost' } }

const maps = {
  'backoffice.hlo.app': 'production',
  'hlo-testing-backoffice.web.app': 'staging',
}

const host = window.location.host
const found = maps[host]

const env = found ? found : 'localhost'
export const config = require(`./config.${env}`)
