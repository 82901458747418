import styled from 'styled-components'

import Loading from 'components/loading'

const Layout = (props) => {
  const { children, loading, isHeader, isPadding = true, background } = props
  const css = {}
  console.log('isHeader:', isHeader)
  if (isPadding) {
    css.paddingLeft = '20px'
    css.paddingRight = '20px'
  }

  if (isHeader) {
    css.paddingTop = '75px'
  }

  if (background) {
    css.backgroundColor = background
  }
  return (
    <PageView style={css}>
      <Loading loading={loading}>{children}</Loading>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.max};
  margin: 0 auto;
`

export default Layout
