import styled, { useTheme } from 'styled-components'
import { Input as InputAntd } from 'antd'

import { Find, Remove } from 'icon'

const Input = (props) => {
  const {
    name,
    value = '',
    tag,
    placeholder,
    readonly = false,
    disabled = false,
    isNumber,
    onPressEnter,
    onChange,
    invalid,
  } = props

  const theme = useTheme()
  const color = value === '' ? theme.color_level.grey.high : theme.color.action

  const onValue = (evt) => {
    if (onChange) {
      let val = evt.target.value
      if (isNumber && val !== '') {
        const tmp = +val
        val = tmp === 0 || tmp ? val : value
      }
      onChange(val, name, tag)
    }
  }

  const onClear = () => {
    onChange(isNumber ? '0' : '', name, tag)
  }

  const mention = invalid ? <Invalid>{invalid}</Invalid> : undefined
  const suffix =
    value === '' ? undefined : (
      <Btn onClick={onClear}>
        <Remove />
      </Btn>
    )
  return (
    <PageView>
      <InputAntd
        className={invalid ? 'invalid' : undefined}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        value={value}
        onChange={onValue}
        onPressEnter={onPressEnter}
        allowClear={false}
        prefix={<Find color={color} />}
        suffix={suffix}
      />
      {mention}
    </PageView>
  )
}

const PageView = styled.div`
  input::placeholder {
    font-size: 14px;
    color: ${(p) => p.theme.color.grey};
  }

  .ant-input-affix-wrapper {
    width: 100%;
    height: 48px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 0px 15px;
    border: 0px;
  }

  .ant-input-affix-wrapper > input {
    width: 100%;
    height: 46px;
    color: ${(p) => p.theme.color.blue};
    font-size: 14px;
  }

  .ant-input-affix-wrapper > input:-internal-autofill-selected {
    background-color: white !important;
  }

  .invalid {
    border-color: ${(p) => p.theme.color.error};
  }

  .ant-input-affix-wrapper-disabled {
    color: ${(p) => p.theme.color.error};
    background-color: ${(p) => p.theme.color.error};
  }

  .ant-input[disabled] {
    color: ${(p) => p.theme.color.error};
  }

  .ant-input-affix-wrapper > .ant-input-prefix {
    margin-right: 9px;
  }
`

const Invalid = styled.div`
  color: ${(p) => p.theme.color.error};
  margin-top: 4px;
  font-size: 16px;
`

const Btn = styled.span`
  cursor: pointer;
`

export default Input
