import styled from 'styled-components'
import { Drawer } from 'antd'

import { Close } from 'icon'

const BaseDrawer = (props) => {
  const { title, width, children, visible, onClose } = props

  const text = title ? <Title>{title}</Title> : ''
  return (
    <Drawer
      title={text}
      className="drawer-content"
      width={width}
      placement={'right'}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <Body>
        <Line>
          <Btn onClick={onClose}>
            <Close />
          </Btn>
        </Line>
        <Html>{children}</Html>
      </Body>
    </Drawer>
  )
}

const Body = styled.div`
  padding: 0px 4px;
`

const Html = styled.div`
  width: 100%;
  padding: 15px 0px;
  overflow: auto;
`

const Line = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: ${(p) => p.theme.color.grey.high};
`

const Btn = styled.span`
  position: absolute;
  top: 18px;
  right: 24px;
  cursor: pointer;
`

export default BaseDrawer
