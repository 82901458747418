import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#F36273' } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <circle cx="12" cy="12" r="4" fill="white" />
    </svg>
  )
}
/* eslint-enable */
export default Logo
