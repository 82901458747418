import { Switch } from 'antd'
import styled from 'styled-components'

const SwitchInput = (props) => {
  const { name, value, tag, onChange = () => {} } = props

  const onClick = (e) => {
    onChange(!value, name, tag)
  }

  return (
    <PageView>
      <Switch checked={value} onChange={onClick} />
    </PageView>
  )
}

const PageView = styled.div`
  .ant-switch-checked {
    background-color: ${(p) => p.theme.color.green};
  }
`

export default SwitchInput
