import { Input, DropdownList } from 'components/input'
import { Filter, Row, Col } from 'components/display'

const FilterView = (props) => {
  const { filter = {}, onChange } = props

  const onCh = (val, name) => {
    filter[name] = val
    onChange(filter)
  }

  return (
    <Filter>
      <Row>
        <Col lg={8} md={12} xs={24} position="left">
          <Input
            label="ค้นหาชื่อ"
            name="text"
            value={filter.text}
            onChange={onCh}
          />
        </Col>
        <Col lg={6} md={12} xs={24} position="right">
          <DropdownList
            placeholder="สถานะบริษัท"
            name="status"
            value={filter.status}
            menu={menu_list}
            onChange={onCh}
          />
        </Col>
      </Row>
    </Filter>
  )
}

const menu_list = [
  { name: 'ทั้งหมด', value: 'all' },
  { name: 'เปิด', value: 'active' },
  { name: 'ปิด', value: 'inactive' },
]

export default FilterView
