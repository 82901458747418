import { useState } from 'react'
import { Select } from 'antd'
import styled from 'styled-components'

import Invalid from './Invalid'

const SelectList = (props) => {
  const [isFocus, setIsFocus] = useState(false)
  const {
    menu = [],
    label = 'Please Select',
    value,
    name,
    clear = false,
    disabled = false,
    readonly = false,
    filter = false,
    invalid,
    onChange,
  } = props

  const onFocus = () => setIsFocus(true)
  const onBlur = () => setIsFocus(false)

  const onSelected = (selected) => {
    if (onChange && value !== selected) {
      const item = menu.find((item) => item.value === selected) || {}
      onChange(selected, name, item.tag)
    }
  }

  const choice = menu.map((item, i) => {
    return (
      <Select.Option
        key={i}
        disabled={item.disabled || false}
        value={item.value}
      >
        {item.name}
      </Select.Option>
    )
  })

  const onFilter = (input, option) => {
    const c = (option.children || '').toLowerCase()
    const i = (input || '').toLowerCase()

    return c.indexOf(i) >= 0
  }

  const v = value || ''
  const css = v !== '' || isFocus ? 'fill' : 'empty'
  const readonlyCss = readonly ? ' picker-readonly' : ''
  const invalidCss = invalid ? ' invalid' : ''
  const filterCss = filter ? ' filter' : ''

  return (
    <PageView className={`${css}${readonlyCss}${filterCss}${invalidCss}`}>
      <Label className={css}>{label}</Label>
      <Select
        id={name}
        dropdownClassName="hlo-selector"
        disabled={disabled || readonly}
        placeholder={css === 'fill' ? undefined : label}
        value={value}
        allowClear={clear}
        showArrow={true}
        onChange={onSelected}
        showSearch={true}
        filterOption={onFilter}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {choice}
      </Select>
      <Invalid invalid={invalid} />
    </PageView>
  )
}

const PageView = styled.div`
  position: relative;
  font-size: 14px;

  .ant-select {
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.input.border};
  }

  .ant-select .ant-select-selector .ant-select-selection-placeholder {
    color: ${(p) => p.theme.color_level.grey.high};
    text-align: left;
  }

  .ant-select .ant-select-selector {
    border: 1px solid #cccccc;
    border-radius: 8px;
    border: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    text-align: left;
  }

  .ant-select .ant-select-selector .ant-select-selection-item {
    color: ${(p) => p.theme.color_level.grey.high};
    padding-top: 14px;
    font-size: 14px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${(p) => p.theme.color_level.grey.high};
  }

  &.invalid .ant-select .ant-select-selector .ant-select-selection-placeholder {
    color: ${(p) => p.theme.color.error} !important;
  }

  &.filter
    .ant-select-focused
    .ant-select-selector
    .ant-select-selection-placeholder {
    top: -10px;
    position: relative;
    visibility: visible !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    margin-top: 16px;
    font-size: 16px;
  }
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.medium};
  position: absolute;
  left: 12px;
  top: 2px;
  z-index: 1;
  transition: all 0.3s ease;

  &.empty {
    display: none;
  }
`

export default SelectList
