import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { cloneDeep } from 'lodash'

import { message } from 'message'
import { Header } from 'components/table'
import { Content } from 'components/display'
import { PrimaryBtn } from 'components/button'
import Loading from 'components/loading'
import CompanyMenu from 'pages/company/detail/Menu'

import { header_list } from './col'
import RowItem from './RowItem'
import RoleCreator from './RoleCreator'

const original = {
  name: '',
  dashboard: { module: 'view' },
  company: { module: 'manage' },
  employee: { module: 'manage', private_information: 'manage' },
  leave_req: { module: 'manage' },
  doc: { module: 'manage' },
  overtime: { module: 'manage' },
  reimburse: { module: 'manage' },
  attendance: { module: 'manage' },
  payroll: { module: 'manage' },
  admin: { module: 'manage' },
  setting: { module: 'manage' },
}

const CompanyRole = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(undefined)

  const { company_id } = useParams()
  const { role_list = [] } = props.userRole.toJS()

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)
        await props.userRole.getUserRoleList({ company_id: id })
      } catch (e) {
        message.error(e.message)
      }
      setLoading(false)
    },
    [props.userRole]
  )

  useEffect(() => {
    onLoad(company_id)
  }, [onLoad, company_id])

  const onCreate = () => setData(cloneDeep(original))
  const onEdit = (val) => setData({ ...val })
  const onClose = () => {
    setData(undefined)
  }

  const content = role_list.map((it, i) => {
    return <RowItem key={i} onEdit={onEdit} company_id={company_id} item={it} />
  })

  const btn = (
    <Button>
      <PrimaryBtn text="เพิ่มสิทธิ์การเข้าถึง" onClick={onCreate} />
    </Button>
  )

  return (
    <Content>
      <CompanyMenu id={company_id} selected={'role'} content={btn}>
        <Loading loading={loading}>
          <Header list={header_list} />
          {content}
        </Loading>
      </CompanyMenu>
      <RoleCreator
        visible={data !== undefined}
        company_id={company_id}
        doc={data}
        onClose={onClose}
      />
    </Content>
  )
}

const Button = styled.div`
  width: 214px;
  float: right;
`

export default inject('userRole')(observer(CompanyRole))
