export const col = {
  basic: { lg: 4, md: 6, xs: 12 },
  email: { lg: 7, md: 7, xs: 12 },
  name: { lg: 7, md: 7, xs: 12 },
  date: { lg: 4, md: 4, xs: 12 },
}

export const header_list = [
  { title: 'ชื่อ', size: col.name },
  { title: 'เบอร์โทร', size: col.basic },
  { title: 'อีเมล', size: col.email },
  { title: 'วันที่เริ่ม', size: col.date },
]
