import { useState, useEffect, useCallback } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import Loading from 'components/loading'
import Dashboard from 'pages/dashboard'
import LoginPage from 'pages/login'
import Consent from 'pages/consent'
import CompanyList from 'pages/company/list'
import CompanyInfo from 'pages/company/detail/information'
import CompanyMember from 'pages/company/detail/member'
import CompanyRole from 'pages/company/detail/role'
import CompanyFeature from 'pages/company/detail/feature'
import Migration from 'pages/migration'
import MainLayout from 'menu'

const App = (props) => {
  const [loading, setLoading] = useState(true)
  const isLogin = props.member.isLogin()

  const onCheck = useCallback(async () => {
    try {
      setLoading(true)
      await props.member.checkLoginUser()
    } catch (e) {
      console.log('check user:', e.message)
    }
    setLoading(false)
  }, [props.member])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <Routes>
        <Route path="*" element={<Loading />} />
      </Routes>
    )
  } else if (!isLogin) {
    return (
      <Routes>
        <Route path="*" element={<LoginPage />} />
      </Routes>
    )
  }

  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/consent/:typ" element={<Consent />} />
        <Route path="/company" element={<CompanyList />} />
        <Route path="/company/:company_id" element={<CompanyInfo />} />
        <Route path="/company/:company_id/member" element={<CompanyMember />} />
        <Route path="/company/:company_id/role" element={<CompanyRole />} />
        <Route
          path="/company/:company_id/feature"
          element={<CompanyFeature />}
        />
        <Route path="/migrations" element={<Migration />} />
      </Routes>
    </MainLayout>
  )
}

export default inject('member')(observer(App))
