import styled from 'styled-components'

import { EditBtn } from 'components/circle'

const Content = (props) => {
  const { children, title, onEdit } = props

  const btn = onEdit ? <EditBtn onClick={onEdit} /> : undefined
  return (
    <PageView>
      <Header>
        <Title>{title}</Title>
        {btn}
      </Header>
      <Body>{children}</Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 20px;
`

const Header = styled.div`
  width: 100%;
  padding: 12px 24px;
  border: 0px solid ${(p) => p.theme.color.border};
  border-bottom-width: 1px;
  display: flex;
  align-items: center;
`

const Body = styled.div`
  width: 100%;
  padding: 24px;
`

const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 18px;
`

export default Content
