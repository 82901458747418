import styled from 'styled-components'

import { Radio } from 'icon'

const RadioInput = (props) => {
  const { name, label, value = '', tag, onChange = () => {} } = props

  const onValue = () => {
    onChange(value, name, tag)
  }

  const color = value ? undefined : 'white'
  return (
    <Btn onClick={onValue}>
      <Radio color={color} />
      <label>{label}</label>
    </Btn>
  )
}

const Btn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  label {
    margin-left: 15px;
  }

  svg {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
    border-radius: 50%;
  }
`

export default RadioInput
