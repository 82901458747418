import styled from 'styled-components'
import { Col } from 'antd'

import { Link } from 'components/link'

const ColView = (props) => {
  const { link, align, children } = props

  const getContent = () => {
    switch (align) {
      case 'center':
        return <Center>{children}</Center>
      case 'right':
        return <Right>{children}</Right>
      default:
        return <Left>{children}</Left>
    }
  }

  const txt = getContent()
  const content = link ? <Link>{txt}</Link> : txt
  return <Col {...props}>{content}</Col>
}

const Center = styled.div`
  text-align: center;
`

const Right = styled.div`
  text-align: right;
  float: right;
`

const Left = styled.div`
  padding-right: 20px;
`

export default ColView
