import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Input, InputMobile, DatePicker } from 'components/input'
import { Line } from 'components/display'
import { EditDrawer } from 'drawer'
import { message } from 'message'
import { validator } from 'utils'

import rules from './rules'

const EmployeeCreator = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [validate, setValidate] = useState({})

  const { visible, company_id, doc = {}, lang, onClose } = props

  useEffect(() => {
    if (visible) {
      setData({ ...doc })
    }
  }, [visible])

  const onCh = (val, name) => {
    data[name] = val
    setData({ ...data })

    delete validate[name]
    setValidate({ ...validate })
  }

  const onSave = async () => {
    try {
      const checker = validator.process(rules, data)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setLoading(true)

      await props.employee.createEmployee({ company_id }, data)
      onClose()
      message.success(
        lang.getMessage(
          'created employee successfully',
          'เพิ่มพนักงานเรียบร้อยแล้ว'
        )
      )
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  return (
    <EditDrawer
      title={lang.getMessage('Employee Information', 'ข้อมูลพนักงาน')}
      text={lang.getMessage('Save', 'บันทึก')}
      visible={visible}
      loading={loading}
      onClose={onClose}
      onSave={onSave}
    >
      <PageView>
        <Line>
          <Input
            label={lang.getMessage('Name', 'ชื่อ')}
            name="name"
            value={data.name}
            onChange={onCh}
            invalid={lang.getValidate(validate.name, 'name', 'ชื่อ')}
          />
        </Line>
        <Line>
          <Input
            label={lang.getMessage('Surname', 'นามสกุล')}
            name="surname"
            value={data.surname}
            onChange={onCh}
            invalid={lang.getValidate(validate.surname, 'surname', 'นามสกุล')}
          />
        </Line>
        <Line>
          <Input
            label={lang.getMessage('Nickname', 'ชื่อเล่น')}
            name="nickname"
            value={data.nickname}
            onChange={onCh}
            invalid={lang.getValidate(
              validate.nickname,
              'nickname',
              'ชื่อเล่น'
            )}
          />
        </Line>

        <Line>
          <DatePicker
            label={lang.getMessage('Join Company Date', 'วันเริ่มงาน')}
            name="join_company_at"
            value={data.join_company_at}
            onChange={onCh}
            invalid={lang.getValidate(
              validate.join_company_at,
              'join date',
              'วันเริ่มงาน'
            )}
          />
        </Line>

        <Line>
          <InputMobile
            label={lang.getMessage('Mobile', 'เบอร์โทร')}
            name="mobile"
            value={data.mobile}
            onChange={onCh}
          />
        </Line>

        <Input
          label={lang.getMessage('Email', 'อีเมล')}
          name="email"
          value={data.email}
          onChange={onCh}
          invalid={validate.email}
        />
      </PageView>
    </EditDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('employee', 'lang')(observer(EmployeeCreator))
