import styled from 'styled-components'

import { Layout, Row, Col } from 'components/display'

import Picture from './Picture'
import Form from './Form'

const LoginPage = (props) => {
  return (
    <Layout>
      <PageView>
        <Row align="middle">
          <Col lg={12} md={12} xs={24}>
            <Picture />
          </Col>
          <Col lg={12} md={12} xs={24}>
            <Form />
          </Col>
        </Row>
      </PageView>
    </Layout>
  )
}

const PageView = styled.div`
  padding: 20px;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
`

export default LoginPage
