import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const Url = `${config.api}/v1/admin/account/user-role`
let state
export class AdminRole extends BaseStore {
  constructor() {
    super()
    this.observable({
      role_list: [],
      menu_list: [],
    })
    state = this
  }

  async getMenuList({ company_id }) {
    const url = `${Url}/${company_id}`
    const res = await http.get(url)

    const list = res.body || []
    const menu = list.map((it) => {
      const { user_role_id, name } = it
      return { name, value: user_role_id }
    })

    runInAction(() => {
      state.menu_list = menu
    })
  }

  async getUserRoleList({ company_id }) {
    const url = `${Url}/${company_id}`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.role_list = list
    })
  }

  async createUserRole({ company_id }, json = {}) {
    const url = `${Url}/${company_id}`
    const res = await http.post(url, { json })

    const data = res.body

    const list = this.toJS().role_list
    list.push(data)

    runInAction(() => {
      state.role_list = list
      state.menu_list = []
    })
  }

  async updateUserRole({ company_id, user_role_id }, json = {}) {
    const url = `${Url}/${company_id}/${user_role_id}`
    const res = await http.put(url, { json })

    const data = res.body
    const list = this.toJS().role_list

    const index = list.findIndex((it) => it.user_role_id === user_role_id)
    if (index !== -1) {
      list[index] = data
      runInAction(() => {
        state.role_list = list
        state.menu_list = []
      })
    }
  }

  async deleteUserRole({ company_id, user_role_id }) {
    const url = `${Url}/${company_id}/${user_role_id}`
    await http.delete(url)

    const list = this.toJS().role_list
    const index = list.findIndex((it) => it.user_role_id === user_role_id)
    if (index !== -1) {
      list.splice(index, 1)

      runInAction(() => {
        state.role_list = list
        state.menu_list = []
      })
    }
  }
}

export default new AdminRole()
