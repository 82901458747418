import { Row, Col, DisplayItem } from 'components/display'
import { format } from 'utils'

import { col } from './col'

const ItemInfo = (props) => {
  const { label = '', status, item } = props

  return (
    <Row>
      <Col {...col.basic} position="left-responsive">
        <DisplayItem label={label} value={status} />
      </Col>
      <Col {...col.basic} position="center-responsive">
        <DisplayItem label="Fee Type" value={item.fee_type} />
      </Col>
      <Col {...col.basic} position="right-responsive">
        <DisplayItem label="Fee" value={format.toDigi(item.fee)} />
      </Col>
    </Row>
  )
}

export default ItemInfo
