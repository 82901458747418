import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { message } from 'message'
import Loading from 'components/loading'
import { Content, Section } from 'components/display'
import CompanyMenu from 'pages/company/detail/Menu'

import DisplayInfo from './DisplayInfo'
import EditInfo from './EditInfo'

const FeatureInfo = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(undefined)

  const { company_id } = useParams()
  const { lang } = props

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)
        await props.company.getCompanyFeature(id)
      } catch (e) {
        message.error(e.message)
      }
      setLoading(false)
    },
    [props.company]
  )

  useEffect(() => {
    onLoad(company_id)
  }, [onLoad, company_id])

  const { feature } = props.company.toJS()

  const onStartEdit = (val) => setData(feature)
  const onChange = (val) => setData({ ...val })
  const onClose = () => setData(undefined)

  const content = data ? (
    <EditInfo
      company_id={company_id}
      doc={data}
      onChange={onChange}
      onClose={onClose}
    />
  ) : (
    <DisplayInfo doc={feature} />
  )

  const editBtn = data ? undefined : onStartEdit
  return (
    <Content>
      <CompanyMenu id={company_id} selected={'feature'}>
        <Loading loading={loading}>
          <Section
            title={lang.getMessage('Feature', 'ฟีเจอร์')}
            onEdit={editBtn}
          >
            {content}
          </Section>
        </Loading>
      </CompanyMenu>
    </Content>
  )
}

export default inject('company', 'lang')(observer(FeatureInfo))
