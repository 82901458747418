import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent_id: undefined,
  ver: '',
  consent_type: 'privacy',
  status: 'active',
  title: '',
  short_content: '',
  content: '',
}

const Url = `${config.api}/v1/public/account/consent`
const AdminUrl = `${config.api}/v1/admin/account/consent`

let state
export class Consent extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })
    state = this
  }

  async getConsent(typ) {
    const url = `${Url}/${typ}`
    const resp = await http.get(url)
    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original)
    runInAction(() => {
      state.doc = tmp
    })
  }

  async saveConsent(typ, json = {}) {
    const url = `${AdminUrl}/${typ}`
    await http.put(url, { json })
  }

  setConsent(doc) {
    runInAction(() => {
      state.doc = doc
    })
  }
}

const store = new Consent()
export default store
