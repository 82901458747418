import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Avatar } from 'components/display'
import { Row, Col } from 'components/table'
import { RemoveBtn, EditBtn, Btn } from 'components/circle'
import { message } from 'message'
import { ConfirmDialog } from 'dialog'
import { format } from 'utils'

import { col } from './col'

const RowItem = (props) => {
  const [isConfirm, setIsConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const { company_id, item, onEdit, onPassword } = props

  const onConfirm = () => setIsConfirm(true)
  const onClose = () => setIsConfirm(false)
  const clickEdit = () => onEdit(item)
  const clickPassword = () => onPassword(item)

  const onDelete = async () => {
    try {
      onClose()
      setLoading(true)

      const { admin_customer_id } = item
      await props.userAdmin.deleteAdmin({ company_id, admin_customer_id })
      message.success('ลบผู้ดูแลสำเร็จ')
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  return (
    <>
      <Row align="middle">
        <Col {...col.img}>
          <Avatar img_url={item.img_url} />
        </Col>
        <Col {...col.name}>{format.toUsername(item)}</Col>
        <Col {...col.role}>{item.role_name}</Col>
        <Col {...col.btn} align="right">
          <Button>
            <EditBtn onClick={clickEdit} />
            <Btn onClick={clickPassword} />
            <RemoveBtn loading={loading} onClick={onConfirm} />
          </Button>
        </Col>
      </Row>
      <ConfirmDialog
        title="ลบผู้ดูแล"
        message={`คุณต้องการลบผู้ดูแลออก (${item.name}) ใช่มัย?`}
        visible={isConfirm}
        onClose={onClose}
        onConfirm={onDelete}
      />
    </>
  )
}

const Button = styled.div`
  width: 100%;
  display: flex;
  column-gap: 8px;
`

export default inject('userAdmin')(observer(RowItem))
