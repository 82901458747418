import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Btn } from 'components/button'
import { message } from 'message'

const Migrations = (props) => {
  const [loading, setLoading] = useState(false)

  const { counter } = props.migration.toJS()

  const onProcess = async () => {
    try {
      setLoading(true)

      await props.migration.process()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  return (
    <PageView>
      <Title>Migrations ({counter})</Title>
      <Button>
        <Btn loading={loading} text="Run" onClick={onProcess} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 24px;
`

const Title = styled.div`
  width: 100%;
`

const Button = styled.div`
  width: 200px;
  margin-top: 24px;
`

export default inject('migration', 'lang')(observer(Migrations))
