import styled from 'styled-components'

import { Input, InputMobile } from 'components/input'
import { Line } from 'components/display'

const CompanyCreator = (props) => {
  const { data = {}, validate = {}, onChange } = props

  const onCh = (val, name) => {
    data[name] = val
    onChange(data)
  }

  return (
    <PageView>
      <Line>
        <Input
          label="ชื่อ"
          name="name"
          value={data.name}
          onChange={onCh}
          invalid={validate.name}
        />
      </Line>
      <Line>
        <InputMobile
          label="เบอร์โทร"
          name="mobile"
          value={data.mobile}
          onChange={onCh}
        />
      </Line>
      <Input label="อีเมล" name="email" value={data.email} onChange={onCh} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default CompanyCreator
