import styled from 'styled-components'

import picture from './img.svg'

const Picture = (props) => {
  return (
    <PageView>
      <Img src={picture} />
      <Text>Work smart</Text>
      <Text>Play hard</Text>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 0px 20px;

  ${(p) => p.theme.media.mobile} {
    padding: 0px;
  }
`

const Img = styled.img`
  width: 100%;
  margin-bottom: 20px;
  ${(p) => p.theme.media.mobile} {
    margin-bottom: 0px;
  }
`

const Text = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  ${(p) => p.theme.media.mobile} {
    display: none;
  }
`

export default Picture
