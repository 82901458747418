import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

const SaveBtn = (props) => {
  const { loading, text = 'บันทึก', onClick } = props

  return (
    <PageView>
      <Button>
        <PrimaryBtn loading={loading} text={text} onClick={onClick} />
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const Button = styled.div`
  width: 100%;
  max-width: 214px;
`

export default SaveBtn
