const config = {
  mode: 'staging',
  firebase: {
    apiKey: 'AIzaSyDigCO_mjybeebbChrBjuJJwg4fAxGBcTk',
    authDomain: 'hlo-testing.firebaseapp.com',
    databaseURL: 'https://hlo-testing.firebaseio.com',
    projectId: 'hlo-testing',
    storageBucket: 'hlo-testing.appspot.com',
    messagingSenderId: '185162095130',
    appId: '1:185162095130:web:353045b744df53d752cfa3',
  },
  api: 'https://api-staging.hlo.app/api',
}

module.exports = config
