import { Header } from 'components/table'

import { header_list } from './col'
import RowItem from './RowItem'

const DataList = (props) => {
  const { list = [] } = props
  const content = list.map((it, i) => {
    return <RowItem item={it} key={i} />
  })

  return (
    <>
      <Header list={header_list} />
      {content}
    </>
  )
}

export default DataList
