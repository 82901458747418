import BaseBtn from './BaseBtn'

const Btn = (props) => {
  const { disabled, onClick, text, loading } = props

  return (
    <BaseBtn
      text={text}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      color={'grey'}
      bg_color={'white'}
    />
  )
}

export default Btn
