import { runInAction } from 'mobx'

import { http, format } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const Url = `${config.api}/v1/admin/account/employee`
let state
export class Employee extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: {},
    })
    state = this
  }

  async getMenuList({ company_id }) {
    const url = `${Url}/${company_id}/all`
    const res = await http.get(url)

    const list = res.body || []

    const menu_list = list.map((it) => {
      return { name: format.toUsername(it), value: it.user_id }
    })
    runInAction(() => {
      state.menu_list = menu_list
    })
  }

  async createEmployee({ company_id }, json = {}) {
    const url = `${Url}/${company_id}`
    const res = await http.post(url, { json })
    const data = res.body

    const menu_list = this.toJS().menu_list
    menu_list.push({
      name: format.toUsername(data),
      value: data.user_id,
    })
    runInAction(() => {
      state.menu_list = menu_list
    })
  }
}

const store = new Employee()
export default store
