import { useState, useEffect, useRef } from 'react'
import JoditEditor from 'jodit-react'
import styled from 'styled-components'

const HtmlEditor = (props) => {
  const { onChange, init, name, value } = props

  const editor = useRef(null)
  const [content, setContent] = useState('')

  useEffect(() => {
    setContent(value || '')
  }, [init])

  const onEditorChange = (val) => {
    setContent(val)
    onChange(val, name)
  }

  return (
    <HtmlArea>
      <JoditEditor
        ref={editor}
        placeholder="AAA"
        value={content || ''}
        config={config}
        tabIndex={1}
        onBlur={onEditorChange}
        // onChange={(val) => onChange(val, name)}
      />
    </HtmlArea>
  )
}

const buttons = [
  'bold',
  'underline',
  'italic',
  'strikethrough',
  'ul',
  'ol',
  'indent',
  'outdent',
  'left',
  'fontsize',
  'brush',
  'hr',
  'undo',
  'redo',
]

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  placeholder: '',
  showCharsCounter: false,
  showWordsCounter: false,
  buttons,
  buttonsMD: buttons,
  buttonsXS: buttons,
}

const HtmlArea = styled.div`
  background-color: white;
  padding: 2px;
  border-radius: 4px;
`

export default HtmlEditor
