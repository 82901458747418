import { Close } from 'icon'

import BaseBtn from './BaseBtn'

const RemoveBtn = (props) => {
  const { disabled, onClick, loading, tag } = props

  return (
    <BaseBtn
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      tag={tag}
      icon={<Close color="white" />}
      color={'white'}
      bg_color={'#626262'}
      border_color={'#626262'}
    />
  )
}

export default RemoveBtn
