import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { HtmlEditor, Input, InputArea } from 'components/input'

const Detail = (props) => {
  const { doc = {} } = props.consent.toJS()

  const onChange = (val, name) => {
    doc[name] = val
    props.consent.setConsent(doc)
  }

  return (
    <PageView>
      <VersionArea>
        <Row>
          <Col {...col.title}>
            <Input
              label="หัวข้อ"
              name="title"
              value={doc.title}
              onChange={onChange}
            />
          </Col>
        </Row>

        <InputArea
          label="รายละเอียด"
          name="short_content"
          value={doc.short_content}
          onChange={onChange}
        />
      </VersionArea>
      <HtmlEditor
        name="content"
        init={doc.consent_id}
        value={doc.content}
        onChange={onChange}
      />
    </PageView>
  )
}

const col = {
  title: { xl: 10, lg: 8, md: 24, xs: 24 },
  detail: { xl: 14, lg: 16, md: 24, xs: 24 },
}

const PageView = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`

const VersionArea = styled.div`
  margin-bottom: 20px;
`

export default inject('consent')(observer(Detail))
