import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'message'
import { validator } from 'utils'
import { EditDrawer } from 'drawer'
import { Input } from 'components/input'
import { Line, Row, Col } from 'components/display'

import Dashboard from './Dashboard'
import Company from './Company'
import Employee from './Employee'
import LeaveReq from './LeaveReq'
import Document from './Document'
import Reimburse from './Reimburse'
import Attendance from './Attendance'
import Overtime from './Overtime'
import Payroll from './Payroll'
import Admin from './Admin'
import Setting from './Setting'
import rules from './rules'

const AdminCreator = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [validate, setValidate] = useState({})

  const { visible, company_id, doc = {}, lang, onClose } = props
  const padding = '0px 0px 8px 0px'

  useEffect(() => {
    if (visible) {
      setData({ ...doc })
    }
  }, [visible])

  const onSave = async () => {
    try {
      const checker = validator.process(rules, data)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setLoading(true)

      const { user_role_id } = doc
      if (user_role_id) {
        await props.userRole.updateUserRole({ company_id, user_role_id }, data)
        message.success(
          lang.getMessage('updated role successfully', 'บันทึกเรียบร้อยแล้ว')
        )
      } else {
        await props.userRole.createUserRole({ company_id }, data)
        message.success(
          lang.getMessage('created role successfully', 'บันทึกเรียบร้อยแล้ว')
        )
      }
      onClose()
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  const onCh = (val, name) => {
    data[name] = val
    setData({ ...data })

    delete validate[name]
    setValidate({ ...validate })
  }

  const onChange = (val) => {
    console.log('change:', val)
    setData({ ...val })
  }

  return (
    <EditDrawer
      title={lang.getMessage('Role Information', 'ข้อมูลสิทธิ์การเข้าถึง')}
      text={lang.getMessage('Save', 'บันทึก')}
      width="720px"
      visible={visible}
      loading={loading}
      onClose={onClose}
      onSave={onSave}
    >
      <PageView>
        <Line>
          <Input
            label={lang.getMessage('Name', 'ชื่อ')}
            name="name"
            value={data.name}
            onChange={onCh}
            invalid={lang.getValidate(validate.name, 'name', 'ชื่อ')}
          />
        </Line>
        <Row padding={padding}>
          <Col {...col} position="left">
            <Dashboard data={data} onChange={onChange} />
          </Col>
          <Col {...col} position="right">
            <Company data={data} onChange={onChange} />
          </Col>
        </Row>

        <Row padding={padding}>
          <Col {...col} position="left">
            <Employee data={data} onChange={onChange} />
          </Col>
          <Col {...col} position="right">
            <Document data={data} onChange={onChange} />
          </Col>
        </Row>

        <Row padding={padding}>
          <Col {...col} position="left">
            <Reimburse data={data} onChange={onChange} />
          </Col>
          <Col {...col} position="right">
            <LeaveReq data={data} onChange={onChange} />
          </Col>
        </Row>

        <Row padding={padding}>
          <Col {...col} position="left">
            <Overtime data={data} onChange={onChange} />
          </Col>
          <Col {...col} position="right">
            <Attendance data={data} onChange={onChange} />
          </Col>
        </Row>

        <Row padding={padding}>
          <Col {...col} position="left">
            <Payroll data={data} onChange={onChange} />
          </Col>
          <Col {...col} position="right">
            <Admin data={data} onChange={onChange} />
          </Col>
        </Row>

        <Row>
          <Col {...col} position="left">
            <Setting data={data} onChange={onChange} />
          </Col>
        </Row>
      </PageView>
    </EditDrawer>
  )
}

const col = {
  lg: 12,
  md: 12,
  xs: 24,
}

const PageView = styled.div`
  width: 100%;
`

export default inject('userRole', 'lang')(observer(AdminCreator))
