import styled from 'styled-components'

import { Row, Col, Card, Label, Line, SaveBtn } from 'components/display'
import { Input, InputArea, InputMobile, Switch } from 'components/input'

const Information = (props) => {
  const {
    saving,
    doc = {},
    validate = {},
    onChange = () => {},
    onValidate = () => {},
    onSave,
  } = props

  const onCh = (val, name) => {
    doc[name] = val

    onChange(doc)

    delete validate[name]
    onValidate(validate)
  }

  const onStatus = (val) => {
    doc.status = val ? 'active' : 'inactive'

    onChange(doc)
  }

  return (
    <PageView>
      <Card>
        <Label text="ข้อมูลทั่วไป" />

        <Line>
          <Row>
            <Col {...col.basic} position="left">
              <Input
                label="ชื่อบริษัท"
                name="name"
                value={doc.name}
                onChange={onCh}
                invalid={validate['name']}
              />
            </Col>
            <Col {...col.basic} position="right">
              <Name>สถานะ</Name>
              <Switch value={doc.status === 'active'} onChange={onStatus} />
            </Col>
          </Row>
        </Line>

        <Line>
          <Row>
            <Col {...col.basic} position="left">
              <InputMobile
                label="เบอร์โทร"
                name="mobile"
                value={doc.mobile}
                onChange={onCh}
              />
            </Col>
            <Col {...col.basic} position="right">
              <Input
                label="อีเมล"
                name="email"
                value={doc.email}
                onChange={onCh}
              />
            </Col>
          </Row>
        </Line>

        <Line>
          <Row>
            <Col {...col.basic} position="left">
              <InputMobile
                label="จังหวัด"
                name="city"
                value={doc.city}
                onChange={onCh}
              />
            </Col>
            <Col {...col.basic} position="right">
              <Input
                label="รหัสไปรษณีย์"
                name="zip_code"
                value={doc.zip_code}
                onChange={onCh}
                isNumber={true}
              />
            </Col>
          </Row>
        </Line>

        <Line>
          <InputArea
            label="ที่อยู่"
            name="address"
            value={doc.address}
            onChange={onCh}
          />
        </Line>

        <SaveBtn loading={saving} onClick={onSave} />
      </Card>
    </PageView>
  )
}

const col = {
  status: { lg: 4, md: 12, xs: 24 },
  basic: { lg: 12, md: 12, xs: 24 },
}

const PageView = styled.div`
  width: 100%;
`

const Name = styled.div`
  width: 100%;
`

export default Information
