const hide = {
  eng: { name: 'Hide', value: 'none' },
  th: { name: 'ซ่อน', value: 'none' },
}

const view = {
  eng: { name: 'View', value: 'view' },
  th: { name: 'แสดง', value: 'view' },
}

const manage = {
  eng: { name: 'Manage', value: 'manage' },
  th: { name: 'จัดการ', value: 'manage' },
}

export const view_menu = {
  eng: [hide.eng, view.eng],
  th: [hide.th, view.th],
}

export const manage_menu = {
  eng: [hide.eng, view.eng, manage.eng],
  th: [hide.th, view.th, manage.th],
}
