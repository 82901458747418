import styled from 'styled-components'

import ItemInfo from './ItemInfo'

const DisplayInfo = (props) => {
  const { doc } = props

  const { fee_type, fee } = doc
  const item = { fee_type, fee }
  return (
    <PageView>
      <ItemInfo label="HLO Application" status={doc.status} item={item} />
      <ItemInfo
        label="Leave Request"
        status={doc.leave_req_status}
        item={doc.leave_req}
      />
      <ItemInfo
        label="Document Request"
        status={doc.doc_status}
        item={doc.doc}
      />
      <ItemInfo
        label="Reimbursement"
        status={doc.reimburse_status}
        item={doc.reimburse}
      />

      <ItemInfo
        label="Attendance"
        status={doc.attendance_status}
        item={doc.attendance}
      />
      <ItemInfo
        label="Timesheet"
        status={doc.timesheet_status}
        item={doc.timesheet}
      />

      <ItemInfo
        label="Payroll"
        status={doc.payroll_status}
        item={doc.payroll}
      />
      <ItemInfo
        label="Overtime"
        status={doc.overtime_status}
        item={doc.overtime}
      />
      <ItemInfo
        label="Salary Advance"
        status={doc.salary_advance_status}
        item={doc.salary_advance}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default DisplayInfo
