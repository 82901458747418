import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { validator } from 'utils'
import { message } from 'message'
import { Content } from 'components/display'
import Loading from 'components/loading'
import CompanyMenu from 'pages/company//detail/Menu'

import rules from './rules'
import Information from './Info'

const CompanyInfo = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [validate, setValidate] = useState({})

  const { company_id } = useParams()
  const { doc = {} } = props.company.toJS()

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)
        await Promise.all([
          props.company.getDoc(id),
          props.province.getProvinceList(),
        ])
      } catch (e) {
        message.error(e.message)
      }
      setLoading(false)
    },
    [props.company, props.province]
  )

  useEffect(() => {
    onLoad(company_id)
  }, [onLoad, company_id])

  const onSave = async () => {
    try {
      const checker = validator.process(rules, doc)
      if (checker.invalid) {
        setValidate(checker.errors)
        validator.focus(checker.errors)
        return
      }

      setSaving(true)

      await props.company.updateCompany(company_id, doc)
      message.success()
    } catch (e) {
      message.error(e.message)
    }

    setSaving(false)
  }

  const onChange = (data) => {
    props.company.setCompany(data)
  }

  const onValidate = (valid = {}) => {
    setValidate({ ...valid })
  }

  return (
    <Content>
      <CompanyMenu id={company_id} selected={'information'}>
        <Loading loading={loading}>
          <Information
            saving={saving}
            doc={doc}
            validate={validate}
            onChange={onChange}
            onValidate={onValidate}
            onSave={onSave}
          />
        </Loading>
      </CompanyMenu>
    </Content>
  )
}

export default inject('company', 'province')(observer(CompanyInfo))
