import styled from 'styled-components'
import { Row } from 'antd'

import { Link } from 'components/link'

const RowView = (props) => {
  const { link, align = 'middle', children } = props

  const row = <Row align={align}>{children}</Row>

  const content = link ? <Link to={link}>{row}</Link> : row

  return <PageView>{content}</PageView>
}

const PageView = styled.div`
  margin-top: 8px;
  padding: 10px 20px;
  border-radius: 12px;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

export default RowView
