import numeral from 'numeral'
import moment from 'moment'

export const isNull = (val) => {
  if (val === undefined || val === null || val === '') return true
  else return false
}

export const toInt = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0')
}

export const toDigi = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0,0')
}

export const precision = (val) => {
  return Math.floor(val * 100) / 100
}

export const toMoney = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0,0.00')
}

export const toText = (val) => {
  if (isNull(val)) return '-'
  else return val
}

export const toDatetime = (val) => {
  if (isNull(val)) return '-'

  const mm = moment(val)
  const year = mm.year() + 543
  const date = mm.format('DD/MM')
  const time = mm.format('HH:mm')
  return `${date}/${year} เวลา ${time} น.`
}

export const toShortDay = (val) => {
  if (isNull(val)) return '-'
  else if (typeof val === 'string') return val.slice(0, 3)
  else return moment(val).format('DD')
}

export const toShortMonth = (val) => {
  if (isNull(val)) return '-'
  else if (typeof val === 'string') return val.slice(0, 3)
  else return moment(val).format('MM')
}

export const toDate = (val) => {
  if (isNull(val)) return '-'
  else return moment(val).format('DD/MM/YYYY')
}

export const toNameDate = (val) => {
  if (isNull(val)) return '-'
  else return moment(val).format('DD MMM YYYY')
}

export const toWeekDate = (val) => {
  if (isNull(val)) return '-'
  else return moment(val).format('dddd')
}

export const toTime = (val) => {
  if (isNull(val)) return '-'
  else return moment(val).format('HH:mm')
}

export const toUsername = (user = {}) => {
  const name = user.name || ''
  const surname = user.surname || ''
  return `${name} ${surname}`
}

export const upperText = (text = '') => {
  if (text.length === 0) return text
  return text[0].toUpperCase() + text.substring(1)
}

export const toThaiDate = (val, txt) => {
  if (isNull(val)) return ''

  const date = moment(val, txt)
  const m = date.month()
  const y = date.year() + 543
  return `${date.date()} ${SHORT_MONTH[m]} ${y % 100}`
}

export const toThaiMonth = (val, txt) => {
  if (isNull(val)) return ''

  const date = moment(val, txt)
  const m = date.month()
  const y = date.year() + 543
  return `${MONTH[m]} ${y}`
}

export const toPattern = (value, pattern) => {
  if (!value) return ''

  let valueIndex = 0
  const list = String(pattern).split('')
  const newList = []

  for (let i = 0; i < pattern.length && valueIndex < value.length; i++) {
    if (list[i] === 'x') {
      newList.push(value[valueIndex++])
    } else {
      newList.push(list[i])
    }
  }

  return newList.join('')
}

export const clearPattern = (text = '', ch = '-') => {
  const value = (text || '').replaceAll(ch, '')

  return value
}

export const toMobile = (text) => {
  const mobile = (text || '').replaceAll('-', '')
  return toPattern(mobile, 'xxx-xxx-xxxx')
}

export const replaceAll = (text, ch, to = '') => {
  const val = `${text || ''}`
  const c = `${ch || ''}`

  return val.replaceAll(c, to)
}

const SHORT_MONTH = {
  0: 'ม.ค.',
  1: 'ก.พ.',
  2: 'มี.ค.',
  3: 'ม.ย.',
  4: 'พ.ค.',
  5: 'มิ.ย.',
  6: 'ก.ค.',
  7: 'ส.ค.',
  8: 'ก.ย.',
  9: 'ต.ค.',
  10: 'พ.ค.',
  11: 'ธ.ค.',
}

const MONTH = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}
