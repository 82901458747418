import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const feature = { status: 'active', cost: 0 }
const origin = {
  leave_req: cloneDeep(feature),
  doc: cloneDeep(feature),
  reimburse: cloneDeep(feature),
  attendance: cloneDeep(feature),
  timesheet: cloneDeep(feature),
  payroll: cloneDeep(feature),
  overtime: cloneDeep(feature),
  salary_advance: cloneDeep(feature),
}

const Url = `${config.api}/v1/admin/account/company`
let state
export class Company extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
        list: [],
      },
      doc: {},
      feature: cloneDeep(origin),
    })
    state = this
  }

  getQuery({ status, text }) {
    let q = status ? `&status=${status}` : ''
    q += text ? `&text=${text}` : ''

    return q
  }

  async getList({ status, text, index = 1, per_page = 20 }) {
    const q = this.getQuery({ status, text })
    const url = `${Url}?index=${index}&per_page=${per_page}${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.page.index = index
      state.display.page.per_page = per_page
      state.display.list = list
    })
  }

  async getCount({ status, text }) {
    const q = this.getQuery({ status, text })
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.display.page.total = counter || 0
    })
  }

  async getDoc(id) {
    const url = `${Url}/${id}/detail`
    const resp = await http.get(url)

    const data = resp.body

    runInAction(() => {
      state.doc = data
    })
  }

  async createCompany(json = {}) {
    const url = Url
    const resp = await http.post(url, { json })

    return resp.body
  }

  async updateCompany(id, json = {}) {
    const url = `${Url}/${id}/detail`
    const resp = await http.put(url, { json })

    return resp.body
  }

  async getAdminList(id) {
    const url = `${Url}/${id}/admin`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.admin_list = list
    })
  }

  setCompany(data) {
    runInAction(() => {
      state.doc = data
    })
  }

  async getCompanyFeature(company_id) {
    const url = `${Url}/${company_id}/feature`
    const res = await http.get(url)

    const data = res.body

    runInAction(() => {
      state.feature = data
    })
  }

  async updateCompanyFeature(company_id, json) {
    const url = `${Url}/${company_id}/feature`
    const res = await http.put(url, { json })

    const data = res.body

    runInAction(() => {
      state.feature = data
    })
  }
}

const store = new Company()
export default store
