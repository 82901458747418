import styled from 'styled-components'

const Card = (props) => {
  const { children } = props

  return <PageView>{children}</PageView>
}

const PageView = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
`

export default Card
