import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const Dashboard = (props) => {
  return (
    <PageView>
      <Title>Dashboard</Title>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  background-color: white;
`

const Title = styled.div`
  width: 100%;
  background-color: white;
`

export default inject('lang')(observer(Dashboard))
