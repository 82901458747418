import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { message } from 'message'
import { Header } from 'components/table'
import { Content, Row, Col } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'
import Loading from 'components/loading'
import CompanyMenu from 'pages/company/detail/Menu'

import { header_list } from './col'
import RowItem from './RowItem'
import AdminEditor from './AdminEditor'
import EmployeeCreator from './EmployeeCreator'
import PasswordEditor from './PasswordEditor'

const CompanyMember = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(undefined)
  const [employee, setEmployee] = useState(undefined)
  const [password, setPassword] = useState(undefined)

  const { company_id } = useParams()
  const { admin_list = [] } = props.userAdmin.toJS()

  const onLoad = useCallback(
    async (id) => {
      try {
        setLoading(true)

        const condition = { company_id: id }
        await Promise.all([
          props.userAdmin.getAdminList(condition),
          props.userRole.getMenuList(condition),
        ])
      } catch (e) {
        message.error(e.message)
      }
      setLoading(false)
    },
    [props.userAdmin, props.userRole]
  )

  useEffect(() => {
    onLoad(company_id)
  }, [onLoad, company_id])

  const onCreate = () => setData({})
  const onEdit = (val) => setData({ ...val })
  const onClose = () => setData(undefined)
  const onCreateEmployee = () => setEmployee({})
  const onCloseEmployee = () => setEmployee(undefined)

  const onUpdatePassword = (val) => setPassword({ ...val })
  const onClosePassword = () => setPassword(undefined)

  const content = admin_list.map((it, i) => {
    return (
      <RowItem
        key={i}
        company_id={company_id}
        item={it}
        onEdit={onEdit}
        onPassword={onUpdatePassword}
      />
    )
  })

  const btn = (
    <Row>
      <Col span={8} />
      <Col span={8} position="left">
        <PrimaryBtn text="เพิ่มผู้ดูแล" onClick={onCreate} />
      </Col>
      <Col span={8} position="right">
        <Btn text="เพิ่มพนักงาน" onClick={onCreateEmployee} />
      </Col>
    </Row>
  )

  return (
    <Content>
      <CompanyMenu id={company_id} selected={'member'} content={btn}>
        <Loading loading={loading}>
          <Header list={header_list} />
          {content}
        </Loading>
      </CompanyMenu>
      <AdminEditor
        visible={data !== undefined}
        company_id={company_id}
        doc={data}
        onClose={onClose}
      />
      <EmployeeCreator
        visible={employee !== undefined}
        company_id={company_id}
        doc={employee}
        onClose={onCloseEmployee}
      />
      <PasswordEditor
        visible={password !== undefined}
        doc={password}
        onClose={onClosePassword}
      />
    </Content>
  )
}

export default inject('userAdmin', 'userRole')(observer(CompanyMember))
