import styled, { useTheme } from 'styled-components'

import { Remove } from 'icon'
import { format } from 'utils'

import Invalid from './Invalid'

const Password = (props) => {
  const theme = useTheme()
  const {
    name,
    label,
    value = '',
    tag,
    readonly = false,
    disabled = false,
    clear = false,
    pattern,
    charPattern,
    isNumber,
    limit = 900000,
    onPressEnter,
    onChange = () => {},
    invalid,
    prefix,
  } = props

  const onValue = (evt) => {
    if (onChange) {
      let val = evt.target.value
      if (pattern) {
        val = format.clearPattern(val, charPattern)
      }

      if (isNumber && val !== '') {
        const tmp = +val
        val = tmp === 0 || tmp ? val : value
      }

      if (val.length <= limit) {
        onChange(val, name, tag)
      }
    }
  }

  const onClear = () => {
    if (onChange) {
      onChange(isNumber ? '0' : '', name, tag)
    }
  }

  const text = pattern ? format.toPattern(value, pattern) : value + ''
  const invalidCss = invalid ? ' invalid' : ''
  const activeCss = text !== '' ? ' active' : ''
  const disableCss = disabled ? ' disabled' : ''

  const suffix =
    text === '' || clear === false ? undefined : (
      <Btn onClick={onClear}>
        <Remove color={theme.color_level.grey.high} />
      </Btn>
    )

  return (
    <>
      <Content className={`${invalidCss}${activeCss}${disableCss}`}>
        <InputValue
          id={name}
          className={invalidCss}
          placeholder={label}
          type="text"
          autoComplete="off"
          required
          disabled={disabled}
          value={text}
          onChange={onValue}
        />
        {suffix}
      </Content>
      <Invalid invalid={invalid} />
    </>
  )
}

const Content = styled.div`
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 12px 14px 14px 14px;
  border: 1px solid #cccccc;

  &.active {
    border: 1px solid ${(p) => p.theme.color.black};
  }

  &.disabled {
    background-color: ${(p) => p.theme.input.disable};
  }

  &.invalid {
    border: 1px solid ${(p) => p.theme.color.error};
  }
`

const InputValue = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;

  &.invalid {
    color: ${(p) => p.theme.color.error} !important;
  }

  &:disabled {
    background-color: ${(p) => p.theme.input.disable};
  }
`

const Btn = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 10px;
`

export default Password
