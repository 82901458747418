import { inject, observer } from 'mobx-react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Logo } from 'icon'

const { Sider } = Layout

const Icon = (props) => {
  return (
    <i className="anticon">
      <i className={props.icon} />
    </i>
  )
}

const SideMenu = (props) => {
  const toggle = () => {
    // props.menu.toggleMenu()
  }

  const { openning } = props.menu.toJS()

  const content = menu_list.map((item, index) => {
    const submenu = item.submenu || []
    if (submenu.length > 0) {
      const submenu_list = submenu.map((it, i) => {
        return (
          <Menu.Item key={`${index}-${i}`}>
            <Link to={it.link}>{it.name}</Link>
          </Menu.Item>
        )
      })

      const title = (
        <span>
          <Icon icon={item.icon} />
          <span>{item.name}</span>
        </span>
      )
      return (
        <Menu.SubMenu key={index} title={title}>
          {submenu_list}
        </Menu.SubMenu>
      )
    } else {
      return (
        <Menu.Item key={index}>
          <Link to={item.link}>
            <Icon icon={item.icon} />
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      )
    }
  })
  return (
    <OuterMenu>
      <Sider
        className="sider"
        trigger={null}
        width={200}
        collapsible
        collapsed={!openning}
      >
        <Title key="header" onClick={toggle}>
          <Logo size={0.8} color={'white'} />
        </Title>
        <ContentMenu>
          <Menu theme="light" mode="inline" selectedKeys={[]}>
            {content}
          </Menu>
        </ContentMenu>
      </Sider>
    </OuterMenu>
  )
}

const menu_list = [
  {
    key: 'dashboard',
    icon: 'fas fa-chart-line',
    name: 'แดชบอร์ด',
    link: '/',
  },
  {
    key: 'company',
    icon: 'far fa-building',
    name: 'บริษัท',
    link: '/company',
  },
  {
    key: 'user',
    icon: 'fas fa-users',
    name: 'ผู้ใช้งาน',
    link: '/user',
  },
  {
    key: 'consent',
    icon: 'far fa-newspaper',
    name: 'ความยินยอม',
    submenu: [
      {
        key: 'consent.privacy',
        name: 'Privacy',
        link: `/consent/privacy`,
      },
      {
        key: 'consent.terms',
        name: 'Terms and Condition',
        link: `/consent/terms`,
      },
      {
        key: 'consent.cookie',
        name: 'Cookie',
        link: `/consent/cookie`,
      },
    ],
  },
  {
    key: 'setting',
    icon: 'fas fa-tools',
    name: 'ตั้งค่า',
    submenu: [
      {
        key: 'province',
        name: 'จังหวัด',
        link: `/province`,
      },
    ],
  },
  {
    key: 'migrations',
    icon: 'fas fa-chart-line',
    name: 'Migrations',
    link: '/migrations',
  },
]

const OuterMenu = styled.div`
  background-color: ${(p) => p.theme.color.green};
  border-radius: 20px;
  padding: 25px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  .ant-menu-item {
    color: white;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item a {
    color: white;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title,
  i {
    color: white !important;
  }

  .ant-layout-sider {
    background: transparent;
  }

  .ant-menu {
    border: 0px;
    color: white;
    background-color: ${(p) => p.theme.color.green};
    border-radius: 20px;
  }

  @media print {
    display: none !important;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: center;
`

const ContentMenu = styled.div`
  padding-top: 30px;
`

export default inject('menu')(observer(SideMenu))
