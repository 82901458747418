import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'message'
import { Input } from 'components/input'
import { Line } from 'components/display'
import { EditDrawer } from 'drawer'

const PasswordCreator = (props) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  const { visible, doc = {}, lang, onClose } = props

  useEffect(() => {
    if (visible) {
      setPassword('')
    }
  }, [visible])

  const onSave = async () => {
    try {
      setLoading(true)
      const { admin_customer_id } = doc
      await props.userAdmin.updatePasswordAdminAccount({
        admin_customer_id,
        password,
      })
      onClose()

      message.success(
        lang.getMessage(
          'save password successfully',
          'บันทึกรหัสผ่านเรียบร้อยแล้ว'
        )
      )
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  const onPassword = (val) => setPassword(val)

  return (
    <EditDrawer
      title={lang.getMessage('Admin Information', 'ข้อมูลผู้ดูแล')}
      text={lang.getMessage('Save', 'บันทึก')}
      visible={visible}
      loading={loading}
      onClose={onClose}
      onSave={onSave}
    >
      <PageView>
        <Line>
          <Input
            label={lang.getMessage('Name', 'ชื่อ')}
            name="name"
            value={doc.name}
            disabled={true}
          />
        </Line>
        <Line>
          <Input
            label={lang.getMessage('Password', 'รหัสผ่าน')}
            value={password}
            onChange={onPassword}
          />
        </Line>
      </PageView>
    </EditDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('userAdmin', 'lang')(observer(PasswordCreator))
